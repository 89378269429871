import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import './App.css';
import Join from './pages/Join.jsx';
import Contact from './pages/contact.jsx';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Join" element={<Join/>} />
        <Route path="/Contact" element={<Contact/>} />
        <Route path="*" element={<h1>404 Not Found</h1>} />
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
}

export default App;
