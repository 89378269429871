import React from 'react';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import { Helmet } from 'react-helmet';



const Home = () => {
  return (
    <>
    <div>   
      <Helmet>
        <title>ImmersiveSmile | Embark on a journey to a Pain-free Future</title>
        <meta name="description" content="Immersive Smile offers advanced, pain-free Medical procedure solutions using innovative virtual reality (VR) technology to transform patient experiences and set new standards in the healthcare industry." />
        <meta name="keywords" content="Immersive Smile, cystoscopy, pain-free, virtual reality, VR, innovative healthcare, patient experience, Medical procedure technology, pain management ,Virtual reality Cyber Psychological therapy" />
      </Helmet>
      <style>
        {`
         @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
    
        .animate-fadeIn {
          animation: fadeIn 1s ease-in-out;
        }
    
        @keyframes slideUp {
          0% {
            transform: translateY(100%);
          }
          100% {
            transform: translateY(0%);
          }
        }
    
        .animate-slideUp {
          animation: slideUp 1s ease-in-out;
        }
    
        @keyframes slideDown {
          0% {
            transform: translateY(0%);
          }
          100% {
            transform: translateY(100%);
          }
        }
    
        .animate-slideDown {
          animation: slideDown 1s ease-in-out;
        }
    
        @keyframes rotate {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
    
        .animate-rotate {
          animation: rotate 2s linear infinite;
        }
    
        @keyframes pulse {
          0% {
            transform: scale(1);
          }
          50% {
            transform: scale(1.2);
          }
          100% {
            transform: scale(1);
          }
        }
    
        .animate-pulse {
          animation: pulse 1s ease-in-out infinite;
        }
        @keyframes fadeIn {
          0% {
            opacity: 0;
          }
          100% {
            opacity: 1;
          }
        }
      
        @keyframes SlideRight {
          from {
            transform: translateX(100%);
          }
          to {
            transform: translateX(0%);
          }
        }
      
        .animate-fadeInSlideRight {
          animation: fadeIn 1s ease-in-out, SlideRight 1s ease-in-out;
        }
    
        @keyframes fadeInLeft {
          0% {
            opacity: 0;
            transform: translateX(-100%);
          }
          100% {
            opacity: 1;
            transform: translateX(0%);
          }
        }
      
        .animate-fadeInLeft {
          animation: fadeInLeft 1s ease-in-out;
        }
      
        @keyframes slideLeft {
          0% {
            opacity: 0;
            transform: translateX(100%);
          }
          100% {
            opacity: 1;
            transform: translateX(0%);
          }
        }
      
        .animate-slideLeft {
          animation: slideLeft 1s ease-in-out;
        }
    
        `}
      </style>
      
      <div className="container mx-auto px-4">
      <div className="relative" id="home">
        {/* ... */}
        <div className="relative pt-60 ml-auto z-20  animate-fadeIn delay-500">
          <div className="lg:w-2/3 text-center mx-auto">
            <br />
            <AnimationOnScroll animateIn="animate-fadeIn delay-500" >
            <h1
              className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl animate-slideUp delay-9000 animate-fadeIn delay-100"
>
              Embark on a journey to a{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 via-purple-400 to-indigo-600">
                Pain free Future
              </span>
            </h1>
            </AnimationOnScroll>
            <p className="container mx-auto px-4 mt-8 text-gray-700 dark:text-gray-300 animate-fadeIn delay-700">
              { <div
  className="py-8 mt-16 border-y border-gray-100 dark:border-gray-800 grid grid-cols-1 sm:grid-cols-3 gap-6"
>

  <div className="text-left">
    <h1 className="text-gray-200 dark:text-white font-bold text-5xl md:text-6xl xl:text-3xl">
      1.
    </h1>
    <h6 className="text-lg font-semibold text-gray-400 dark:text-white">
      Reduce pain
    </h6>
    <p className="mt-2 text-gray-500">in Patients at Low cost.</p>
  </div>
  <div className="text-left">
    <h1 className="text-gray-200 dark:text-white font-bold text-5xl md:text-6xl xl:text-3xl ">
      2.
    </h1>
    <h6 className="text-lg font-semibold text-gray-400 dark:text-white">
      More comfortable stress-free
    </h6>
    <p className="mt-2 text-gray-500">
      experience during medical procedures
    </p>
  </div>
  <div className="text-left">
    <h1 className="text-gray-200 dark:text-white font-bold text-5xl md:text-6xl xl:text-3xl">
      3.
    </h1>
    <h6 className="text-lg font-semibold text-gray-400 dark:text-white">
      Potential to Become a standard
    </h6>
    <p className="mt-2 text-gray-500">
      part of medical care in the future
    </p>
  </div>
</div>

              
              
              
              }
            </p>
            <div className="mt-16 flex flex-wrap justify-center gap-y-4 gap-x-6">
              {}
            </div>
            { }
          </div>
          </div>
        </div>
      </div>
      </div>
    
    </>
  );
};

export default Home;



