import React from "react";
import Navbar from "../Components/NavBar";
import Footer from "../Components/Footer";
import Header from "./Header";
import VRChild from "../Images/blaine.jpg";
import Patient from "../Images/Patient.png";
import VRpain from "../Images/VRpain.jpg";
import IEEEInsl_1 from "../Images/IEEEInsl.jpeg";
import Elderguardian from "../Images/Edlerguardian.png";
import ImmersiveSMilePluslogo from "../Images/ImmersiveSMilePluslogo.png";
import IEEEInsl_2 from "../Images/IEEEInsl_2.jpg";
import Royal from "../Images/Royal.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // import the required styles

import { Typography, Card } from "@material-tailwind/react";

const Home = () => {
  return (
    <>
      <Navbar></Navbar>
      <Header></Header>

      <div id="blog">
        <div className="container mx-auto px-4">
          <div className="mx-auto max-w-screen-md py-12 -opacity-50 shadow-2xl shadow-gray-600/10 ">
            <Card className="mb-12 overflow-hidden animate-fadeIn delay-500 ">
              <AnimationOnScroll animateIn="animate-fadeIn delay-500">
                <img
                  alt="nature"
                  className="h-[32rem] w-full object-cover object-center "
                  src={VRChild}
                />
              </AnimationOnScroll>
            </Card>
            <Typography
              id="OurClients"
              variant="h2"
              color="blue-gray"
              className="mb-2 "
            >
              What is Immersive Smile Plus?
            </Typography>
            <AnimationOnScroll animateIn="animate-fadeIn delay-500">
              <Typography color="gray" className="font-normal  space-y-30 ">
                Immersive Smile Plus is an innovative virtual reality (VR)
                therapy system designed to alleviate pain and discomfort for
                patients undergoing Medical procedures.
              </Typography>
            </AnimationOnScroll>
          </div>
          <section className="relative isolate overflow-hidden bg-white px-6 py-12 sm:py-16 lg:py-24 xl:py-32 sm:px-8 lg:px-10 xl:px-12">
            <AnimationOnScroll animateIn="animate-fadeIn delay-500"></AnimationOnScroll>
            <AnimationOnScroll animateIn="animate-fadeIn delay-500">
              <Card className="mb-8 sm:mb-12 overflow-hidden animate-fadeIn delay-500 mx-auto max-w-screen-md py-6 sm:py-12 lg:py-16 xl:py-20">
                <div className="flex flex-col-reverse items-center justify-center sm:flex-row">
                  <img
                    alt="Royal Hospital, Sri Lanka"
                    className="object-cover w-full sm:w-1/2 lg:w-1/3 mr-0 sm:mr-8 mb-4 sm:mb-0"
                    src={Royal}
                  />
                  <div className="w-full sm:w-1/2 lg:w-2/3">
                    <Typography
                      variant="h2"
                      color="blue-gray"
                      className="mb-4 sm:mb-2"
                    >
                      Client Highlight: Royal Hospital, Sri Lanka
                    </Typography>
                    <Typography color="gray" className="font-normal space-y-4">
                      We're proud to introduce our Immersive Smile Plus Package
                      to the children's dengue ward at the Royal Hospital, Sri
                      Lanka. A special thanks to them for their support and
                      belief in our mission.
                    </Typography>
                  </div>
                </div>
              </Card>

              <div className="mb-16 sm:mb-20 mx-auto max-w-2xl lg:max-w-4xl py-12 sm:py-16 lg:py-20">
                <img className="mx-auto h-16 sm:h-20" src={Patient} alt="" />
                <figure className="mt-8 sm:mt-10">
                  <div className="absolute inset-0 -z-10 bg-[radial-gradient(45rem_50rem_at_top,theme(colors.indigo.100),white)] opacity-20" />
                  <div className="absolute inset-y-0 right-0 -z-10 sm:-right-1/2 xl:-right-1/3 w-[150%] origin-bottom-left skew-x-[-30deg] bg-white shadow-xl shadow-indigo-600/10 ring-1 ring-indigo-50 sm:mr-16 xl:mr-0 xl:origin-center" />

                  <blockquote className="text-center text-base sm:text-xl font-semibold leading-6 sm:leading-8 text-gray-900">
                    <p>
                      “Thank you...Thank you so much brother..really enjoyed,
                      would like to ask my friends also to come and try it”
                    </p>
                  </blockquote>
                  <figcaption className="mt-6 sm:mt-10">
                    <div className="mt-4 flex items-center justify-center space-x-3 text-sm sm:text-base">
                      <div className=" text-gray-900">
                        9-year-old Dengue Patient -{" "}
                      </div>
                      <svg
                        viewBox="0 0 2 2"
                        width={3}
                        height={3}
                        aria-hidden="true"
                        className="fill-gray-900"
                      ></svg>
                      <div className="text-gray-600">
                        who tried our Immersive Smile Plus VR Application at
                        Royal Hospital Sri Lanka
                      </div>
                    </div>
                  </figcaption>
                </figure>
              </div>
            </AnimationOnScroll>
          </section>

          <div
            id="OurProjects"
            className="grid mb-10 space-y-1 text-center mt-6 flex flex-col gap-1 "
          >
            <h2 className="text-3xl font-bold text-gray-800 md:text-3xl dark:text-white">
              Our Projects
            </h2>
            <p className="lg:mx-auto lg:w-6/12 text-gray-600 dark:text-gray-300">
              These are some of the royalty Projects of ImmersiveSmile
            </p>
          </div>

          <div className="flex justify-center my-7">
            <Card>
              <img
                src={ImmersiveSMilePluslogo}
                alt="ImmersiveSmilePlus logo"
                className="w-400 h-600 object-contain p-7"
                width="256"
                height="300"
              />
              <h5 className="lg:mx-auto text-1xl font-bold  text-center text-gray-800 md:text-1xl dark:text-white">
                Immersive Smile Plus
              </h5>
              <p className="lg:mx-auto  text-center text-gray-600 dark:text-gray-300">
                "Embark on a pain free future"
              </p>
            </Card>

            <Card>
              <img
                src={Elderguardian}
                alt="ImmersiveSmilePlus logo"
                className="w-400 h-600 object-contain p-7"
                width="256"
                height="300"
              />

              <h5 className="lg:mx-auto text-1xl  text-center font-bold text-gray-700 md:text-1xl dark:text-white animate-fadeIn delay-900">
                ElderGuardian
              </h5>
              <p className="lg:mx-auto text-gray-600  text-center dark:text-gray-300">
                "Guardian Angel in your pocket"
              </p>
            </Card>
          </div>

          <div className="grid gap-9 md:grid-cols-2 lg:grid-cols-2 py-12">
            <div className="group p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10 animate-fadeInleft delay-500">
              <div className="relative overflow-hidden rounded-xl">
                <img
                  src={VRpain}
                  alt="art cover"
                  loading="lazy"
                  width="256"
                  height="300"
                  className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105"
                />
              </div>

              <div className="mt-6 relative">
                <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
                  Immersive Smile Plus : Succesfully Tested in Pain Management
                </h3>
                <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
                  Experience the power of our innovative pain management
                  solution, Immersive Smile Plus. Through rigorous testing, this
                  cutting-edge technology has successfully demonstrated its
                  ability to alleviate pain and discomfort in patients in
                  patients. By revolutionizing medical procedures and
                  treatments, Immersive Smile Plus is transforming patient
                  experiences and setting a new standard for the future of
                  healthcare.
                </p>
                <a className="inline-block" href="#">
                  {/* Link content */}
                </a>
              </div>
            </div>

            <div className="group p-6 sm:p-8 rounded-3xl bg-white border border-gray-100 dark:shadow-none dark:border-gray-700 dark:bg-gray-800 bg-opacity-50 shadow-2xl shadow-gray-600/10  animate- in-left delay-500">
              <div className="relative overflow-hidden rounded-xl">
                <Carousel
                  autoPlay
                  infiniteLoop
                  showThumbs={false}
                  showStatus={false}
                  interval={10000}
                  showArrows={false}
                  swipeable
                >
                  <div>
                    <img
                      src={IEEEInsl_1}
                      alt="art cover"
                      loading="lazy"
                      width="1000"
                      height="1000"
                      className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105"
                    />
                  </div>
                  <div>
                    <img
                      src={IEEEInsl_2} // Add the second image source
                      alt="art cover"
                      loading="lazy"
                      width="1000"
                      height="1000"
                      className="h-64 w-full object-cover object-top transition duration-500 group-hover:scale-105"
                    />
                  </div>
                </Carousel>
              </div>

              <div className="mt-6 relative">
                <h3 className="text-2xl font-semibold text-gray-800 dark:text-white">
                  Award-Winning Innovation: Immersive Smile Plus Making an
                  Impact
                </h3>
                <p className="mt-6 mb-8 text-gray-600 dark:text-gray-300">
                  Our Immersive Smile Plus technology has made its mark in the
                  tech and innovation by excelling in prestigious competitions.
                  Winners at the IEEE Innovation Nation Sri Lanka 2022 Grand
                  Finals and The finalist of Microsoft Imagine Cup South East
                  Asia 2023, Immersive Smile Plus has proven its potential to
                  create positive change in the world. Join us in celebrating
                  these achievements and supporting our ongoing commitment to
                  developing groundbreaking projects that push the boundaries of
                  healthcare and pain management.
                </p>
                <a className="inline-block" href="#">
                  {/* Link content */}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Card className="mb-20 overflow-hidden animate-fadeIn delay-500 text-center">
        <AnimationOnScroll animateIn="animate-fadeIn delay-500">
          <div className="flex flex-col items-center justify-center">
            <div className=" mx-auto px-4 py-8 border border-gray-300 rounded-lg">
              <Typography
                variant="h2"
                color="gray"
                className="font-normal text-center"
              >
                ImmersiveSmile is actively seeking partnerships with hospitals
                and clinics in Sri Lanka, and globally.{" "}
              </Typography>
              <Typography>
                {" "}
                If your institution is interested in providing our Virtual
                Reality Therapy Package to your patients, we invite you to join
                us in making a transformative impact. Together, we can change
                the face of patient care.
              </Typography>
            </div>
          </div>
        </AnimationOnScroll>
      </Card>

      <Footer></Footer>
    </>
  );
};

export default Home;
