import React, { useState, useEffect } from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
} from "@material-tailwind/react";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

export default function Example() {
  const [openNav, setOpenNav] = useState(false);

  useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mb- mt-2 flex flex-col gap-1 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-5 text-gray-500">
      <Typography
        as="li"
        variant="small"
        color="gray"
        className="p-1 font-normal"
      >
        <a href="/" className="flex items-center">
          Home
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="gray"
        className="p-1 font-normal"
      >
        <a href="#OurProjects" className="flex items-center">
          Our Projects
        </a>
      </Typography>
      <Typography
        as="li"
        variant="small"
        color="gray"
        className="p-1 font-normal"
      >
          <a href="#OurClients" className="flex items-center">
              Clients
          </a>
      </Typography>
        <Typography
            as="li"
            variant="small"
            color="gray"
            className="p-1 font-normal"
        >
        <a href="/Join" className="flex items-center">
          Join Our Team
        </a>
      </Typography>
    </ul>
  );

  return (
    <>
      <Navbar className="sticky inset-0 z-10 h-max max-w-full rounded-none py-2 px-4 lg:px-8 lg:py-4">
        <div className="flex items-center justify-between text-blue-gray-500">
          <Typography
            as="a"
            href="#"
            className="mr-4 cursor-pointer py-1.5 font-medium"
            color="gray"
          >
            ImmersiveSmile
          </Typography>
          <IconButton
            onClick={() => setOpenNav(!openNav)}
            className="lg:hidden"
          >
            {openNav ? <CloseIcon /> : <MenuIcon />}
          </IconButton>
          <div className="hidden lg:flex items-center gap-4">
            {navList}
            <Button
              size="sm"
              color="gray"
              onClick={() => {
                window.location.href = "/Join";
              }}
            >
              Join Our Team
            </Button>
          </div>
        </div>
        <MobileNav open={openNav}>
          {navList}
          <Button variant="gradient" size="sm" fullWidth className="mb-2">
            Buy Now
          </Button>
        </MobileNav>
      </Navbar>
    </>
  );
}
