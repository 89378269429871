import React from 'react';
import Contact from "../pages/contact";

function Footer() {
  return (
    <footer className="bg-white rounded-lg shadow dark:bg-gray-900 m-4">
      <div className="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div className="sm:flex sm:items-center sm:justify-between">
          <a href="https://flowbite.com/" className="flex items-center mb-4 sm:mb-0">

            <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">ImmersiveSmile</span>
          </a>

          <ul className="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm dark:text-gray-400">

            <li>
              <a href="mailto:Support@ImmersiveSmile.com" className=" sm:flex sm:items-center sm:justify-betweenself-center mr-4 hover:underline md:mr-6 font-medium text-gray-500 sm dark:text-gray-400 "> Hospitals/partnerships: Support@immersivesmile.com </a>


            </li>





          </ul>

        </div>



        <a href="mailto:Support@ImmersiveSmile.com"  className="mr-4 hover:underline md:mr-6  ">Interested in Partnering?
          Contact us today!</a>

        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <span className="block text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="/" className="hover:underline">ImmersiveSmile™</a>. All Rights Reserved.</span>
      </div>
    </footer>
  );
}

export default Footer;
