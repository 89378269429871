import React from 'react';
import vrImage from '../Images/VRwork.jpeg'; // Import the VR image
import Navbar from "../Components/NavBar";
import Footer from '../Components/Footer';

const Join = () => {
  return (
    <>
        <Navbar></Navbar>
        <style>
  {`
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    .animate-fadeIn {
      animation: fadeIn 1s ease-in-out;
    }

    @keyframes slideUp {
      0% {
        transform: translateY(100%);
      }
      100% {
        transform: translateY(0%);
      }
    }

    .animate-slideUp {
      animation: slideUp 1s ease-in-out;
    }

    @keyframes slideDown {
      0% {
        transform: translateY(0%);
      }
      100% {
        transform: translateY(100%);
      }
    }

    .animate-slideDown {
      animation: slideDown 1s ease-in-out;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .animate-rotate {
      animation: rotate 2s linear infinite;
    }

    @keyframes pulse {
      0% {
        transform: scale(1);
      }
      50% {
        transform: scale(1.2);
      }
      100% {
        transform: scale(1);
      }
    }

    .animate-pulse {
      animation: pulse 1s ease-in-out infinite;
    }
    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  
    @keyframes SlideRight {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(0%);
      }
    }
  
    .animate-fadeInSlideRight {
      animation: fadeIn 1s ease-in-out, SlideRight 1s ease-in-out;
    }

    @keyframes fadeInLeft {
      0% {
        opacity: 0;
        transform: translateX(-100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  
    .animate-fadeInLeft {
      animation: fadeInLeft 1s ease-in-out;
    }
  
    @keyframes slideLeft {
      0% {
        opacity: 0;
        transform: translateX(100%);
      }
      100% {
        opacity: 1;
        transform: translateX(0%);
      }
    }
  
    .animate-slideLeft {
      animation: slideLeft 1s ease-in-out;
    }
  
  `}
</style>

      <div className="container mx-auto px-4">
        <div className="relative" id="home">
          <div className="relative pt-40 ml-auto z-20 animate-fadeIn delay-500">
            <div className="lg:w-2/3 text-center mx-auto">
              <h1
                className="text-gray-900 dark:text-white font-bold text-5xl md:text-6xl xl:text-7xl animate-slideUp delay-900"
              >
                Join Our Team
              </h1>
              <h2
                className="text-gray-900 dark:text-white font-semibold text-3xl md:text-4xl xl:text-5xl animate-slideUp delay-900 mt-4"
              >
                Unleash Your Potential with{' '}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-cyan-400 via-purple-400 to-indigo-600">
                  ImmersiveSmile
                </span>
              </h2>
              
              <p className="container mx-auto px-4 mt-8 text-gray-700 dark:text-gray-300 animate-fadeIn delay-700">
                Passionate about virtual and mixed reality? Join ImmersiveSmile and let's innovate together. Send your CV and portfolio to <a href="mailto:Support@ImmersiveSmile.com" className="text-indigo-600">Support@ImmersiveSmile.com</a>.
                <img src={vrImage} alt="Impressive VR" className="w-full mt-8 rounded-lg" /> {/* Add the VR image */}
                <br /><br />
                We're looking for a skilled Game Programmer Intern! If you excel in languages like C# or C++, have experience with Unity or Unreal Engine, and love VR/AR, we want you. Apply now and be part of our dedicated team.
              </p>
              <div className="mt-8">
                <a
                  href="mailto:Support@ImmersiveSmile.com"
                  className="bg-indigo-600 text-white py-3 px-6 rounded-lg font-semibold text-lg hover:bg-indigo-700 transition duration-200"
                >
                  Apply Now



                </a>
                
              </div>
             

            </div>
          </div>
          </div>
        </div>

        { /* Add a new section below */ }
        <div className="relative mt-16">
          <div className="lg:w-2/3 text-center mx-auto">
            <h2
              className="text-gray-900 dark:text-white font-semibold text-3xl md:text-4xl xl:text-5xl animate-slideUp delay-900 mt-4"
            >
              Our Values
            </h2>
            <p className="container mx-auto px-4 mt-8 text-gray-700 dark:text-gray-300 animate-fadeIn delay-700">
              At ImmersiveSmile, we believe in innovation, creativity, and teamwork. We're committed to creating a diverse and inclusive environment where everyone feels welcome and supported. Our values shape the way we work and interact with each other, and we're always looking for talented individuals who share our passion for VR/AR and our values.
            </p>
          </div>
        </div>
<Footer></Footer>
     
    </>
  );
};

export default Join;